html {
  height: 100%;
}
body {
  margin: 0;
  min-height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.pageHeader, .pageNotFoundeHeader {
  font-size: 4vw;
  font-weight: 700;
  margin-left: 80px;
}
.pageNotFoundeSubHeader {
  font-size: 5vw;
}
.newNewsBtnPosition {
  position: absolute;
  top: 140px;
  right: 20px;
}
.card-img-top {
  opacity: 20%;
}
.ligthArea {
  background-color: #ffffff;
  margin: 30px auto;
  padding: 40px;
}
.newsCardTextArea {
  max-height: calc(100% - 6vw);
  overflow: auto;
}
p.card-text {
  margin-bottom: 0;
}
.pointer {
  cursor: pointer;
}
.newsDelArea {
  position: absolute;
  bottom: 4px;
  right: 20px;
}
.newsDelArea button {
  margin-left: 10px;
}
.logoutIcon{
  cursor: pointer;
}
.logoutIcon:hover {
  color: #ffffff;
}
.clearFilterIcon {
  cursor: pointer;
  font-size: 1.3rem;
}
.clearFilterIcon:hover {
  color: #ffffff;
}
.loginGoogleBtn {
  width: 200px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.rdw-editor-wrapper {
  box-sizing: content-box;
  min-height: 300px;
  border: 1px solid #ced4da;
}