@Page {
    margin: 0;
    size: A4;
}

@media print {
    html {
        margin: 0;
    }
    body {
        margin: 0;
        background: none;
    }
    .page {
        padding: 0cm;
        margin: 0;
        border: initial;
        border-radius: initial;
        width: 21cm;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
        background-color: #ffffff;
        position: absolute;
        top: 0;
    }

    .printcard {
        display:block;
        box-sizing: border-box;
        width:70mm;
        height:35.875mm;
        float:left;
        text-align: left;
        vertical-align: top;
        /*border: 1px solid black;*/
        border: none;
        Padding:5mm;
        margin: 0px;
        box-shadow: none;
        transition: inherit;
        border-radius: inherit;
    }

    .printcard:nth-child(22), .printcard:nth-child(23), .printcard:nth-child(24) {
        margin-bottom: 9mm;
    }
    .printcard:nth-child(46), .printcard:nth-child(47), .printcard:nth-child(48) {
        margin-bottom: 10mm;
    }
    .printcard:nth-child(70), .printcard:nth-child(71), .printcard:nth-child(72) {
        margin-bottom: 11mm;
    }
    .printcard:nth-child(94), .printcard:nth-child(95), .printcard:nth-child(96) {
        margin-bottom: 10mm;
    }
    .printcard:nth-child(118), .printcard:nth-child(119), .printcard:nth-child(120) {
        margin-bottom: 11mm;
    }
    .printcard:nth-child(142), .printcard:nth-child(143), .printcard:nth-child(144) {
        margin-bottom: 10mm;
    }


    .printcard:hover {
        box-shadow: none;
    }
    .printbox .addressName {
        font-size: 15px;
        font-weight: normal;
        margin-bottom: 5px;
    }

    .cardIcons, .appHeader, .filterArea, .printHide, .actionButtons, .logout, .userComments {
        display: none !important;
    }
}